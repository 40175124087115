import { useEffect } from 'react'
import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import useDB from './useDB'

/**
 * @description Custom hook to scroll to saved position
 * @returns {Object} {}
 * @example
 * const {} = useScrollTo()
 */
const useScrollTo = () => {
  const [{ CMSProps, ctxInitScroll }, dispatch]: any = useEventsGroupContext()
  const { getModule } = useDB()

  useEffect(() => {
    const timer = setInterval(async () => {
      const evgCompactModuleInDB = await getModule(CMSProps.id)
      if (
        ctxInitScroll &&
        evgCompactModuleInDB?.scrollPosition &&
        evgCompactModuleInDB?.listHeight === document?.getElementById(CMSProps.id)?.clientHeight // not clear yet
        //evgCompactModuleInDB?.documentHeight === document?.body?.clientHeight // not clear yet
      ) {
        window.scrollTo(0, evgCompactModuleInDB?.scrollPosition)
        dispatch({ type: 'SET_INIT_SCROLL', payload: false })
      }
    }, 100)
    return () => {
      clearInterval(timer)
    }
  }, [CMSProps.id, ctxInitScroll, dispatch, getModule])
}

export default useScrollTo
