import { useEffect } from 'react'
import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import useDB from './useDB'
import { debounce } from '@utils'

/**
 * @description Custom hook to update list height in IndexedDB
 * @returns {Object} {}
 * @example
 * const {} = useUpdateListHeight()
 */
const useUpdateListHeight = () => {
  const [{ CMSProps }]: any = useEventsGroupContext()
  const { getModule, updateModule } = useDB()

  const updateListHeightInDB = async () => {
    const evgCompactModuleInDB = await getModule(CMSProps.id)
    if (
      evgCompactModuleInDB &&
      (evgCompactModuleInDB.listHeight !== document?.getElementById(CMSProps.id)?.clientHeight ||
      evgCompactModuleInDB.listHeight !== document?.body?.clientHeight)
    ) {
      await updateModule(evgCompactModuleInDB.id, {
        ...evgCompactModuleInDB,
        listHeight: document?.getElementById(CMSProps.id)?.clientHeight || 0,
        documentHeight: document?.body?.clientHeight || 0
      })
    }
  }

  const onResize = debounce(async () => {
    await updateListHeightInDB()
  }, 500)

  useEffect(() => {
    const timer = setInterval(async () => {
      await updateListHeightInDB()
    }, 3000)

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CMSProps.id])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  return {}
}

export default useUpdateListHeight
