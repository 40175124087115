import { useEffect, useState, useCallback } from 'react'
import { logger, validateAxiosResponse } from '@dmi-mch/utils'
import { Events as EventsService } from '@services'

/**
 * Hook for EventsGroup.tsx
 * Moved to hook on 07/08/2024, to check author lines see repository history
 */
const useEventsGroup = ({ props }) => {
  const { evg_grp1_time_frame } = props
  const [eventsList, setEventsList] = useState<any>(null)
  const linkToBrowsePage = props.evg_grp1_view_all_lnk

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: any = {
    includeFilters: false,
    isFeatured: props.evg_grp1_is_featured === 'true' || null,
    cityId: props.evg_grp1_show_city || null,
    organisers: props.evg_grp1_organiser || null,
    labels: props.evg_grp1_event_label || null,
    eventTypes: props.evg_grp1_category || null,
    access: props.aevd_grp1_event_access || null,
    limit: 3,
    online: false
  }

  const parseDate = useCallback(
    (date) => {
      const datesArr = date.split('|')
      const datesLength = datesArr.length
      const replaceStr = (d) => `${d}:00`
      let dateToSend: any = null
      if (datesLength >= 2) {
        dateToSend = `${replaceStr(datesArr[1])}`
      }
      if (datesLength === 3) {
        dateToSend = `${dateToSend},${replaceStr(datesArr[2])}`
      }
      params.dateRange = dateToSend
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.dateRange]
  )

  const getUpcomingEvents = useCallback(
    async (isUnmounted = false) => {
      let orderBy: any = null

      // Sort by
      switch (props.evg_grp1_sort_by) {
        case 'CREATION_DATE':
          orderBy = 'dateCreated DESC'
          break
        case 'END_DATE':
          orderBy = 'endDate ASC'
          break
        case 'START_DATE':
          orderBy = 'startDate ASC'
          break
        case 'STARTING_SOON':
          orderBy = 'imminence DESC'
          break
        default:
          orderBy = null
      }
      params.orderBy = orderBy

      // Event Type (all, events physical, virtual events and exhibitions)
      switch (props.evg_grp1_event_access_type) {
        case 'EVENTS':
          params.exhibition = false
          break
        case 'EXHIBITIONS':
          params.exhibition = true
          break
        default:
          params.exhibition = null
      }

      // Time Frame
      switch (evg_grp1_time_frame) {
        case 'option_key_1':
          params.startDate = null
          params.endDate = null
          break
        case 'option_key_2':
          params.dateRange = '0,1'
          break
        case 'option_key_3':
          params.dateRange = '0,7'
          break
        default:
          evg_grp1_time_frame && parseDate(evg_grp1_time_frame)
      }

      // Host types (galleries, art basel, partner, all)

      if (props?.aevd_grp1_event_host_type && props?.aevd_grp1_event_host_type !== '0') {
        let listOfHostTypes = props?.aevd_grp1_event_host_type
        listOfHostTypes = listOfHostTypes.split(',')
        let index = listOfHostTypes.indexOf('0')
        if (index !== -1) {
          listOfHostTypes.splice(index, 1)
        }
        listOfHostTypes = listOfHostTypes.toString()
        listOfHostTypes = listOfHostTypes.replace('GALLERIES', 'GALLERY')
        listOfHostTypes = listOfHostTypes.replace('PARTNER', 'SPONSOR')
        params.hostType = listOfHostTypes || null
      }

      // Virtual flag
      if (props.evg_grp1_event_access_type === 'VIRTUAL_EVENTS') {
        params.virtual = true
      }
      if (props.evg_grp1_event_access_type === '0' || !props.evg_grp1_event_access_type) {
        params.virtual = null
      }
      if (props.evg_grp1_event_access_type === 'EVENTS') {
        params.virtual = false
      }

      // request events
      EventsService.getUpcomingEvents(params)
        .then((response: any) => {
          if (validateAxiosResponse(response) && !isUnmounted) {
            setEventsList(response.data.events.items)
            typeof props.cbComponentReady === 'function' && props.cbComponentReady()
          }
        })
        .catch((error) => {
          logger(error)
          if (!isUnmounted) {
            // setIsLoading(false)
          }
        })
      return null
    },
    [evg_grp1_time_frame, params, parseDate, props]
  )

  useEffect(() => {
    let isUnmounted = false
    !eventsList && getUpcomingEvents(isUnmounted)
    return () => {
      isUnmounted = true
    }
  }, [eventsList, getUpcomingEvents])

  return {
    eventsList,
    linkToBrowsePage
  }
}

export default useEventsGroup