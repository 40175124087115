import { useCallback, useEffect, useState } from 'react'
import { EVGCompactTable } from '../../../IndexedDB/config'
import { ICompactTable } from '../../../IndexedDB/types'
import { useSelector } from 'react-redux'

/**
 * @description - Hook for init module EventsGroup (EVG)
 * @returns {isReady} - boolean
 * @example
 * const { isReady } = useInit()
 */
const useInitialConfiguration = () => {
  const reduxShared = useSelector((state) => state.shared)
  const [initialize, setInitialize] = useState(false)

  const deleteModuleFromDB = useCallback(async () => {
    const EVGCompactModulesInDB = await EVGCompactTable.toArray()
    const indexToDelete: number[] = []

    EVGCompactModulesInDB.forEach(async (i: ICompactTable) => {
      indexToDelete.push(i.id)
    })

    if (indexToDelete.length > 0) {
      await EVGCompactTable.bulkDelete(indexToDelete)
      setInitialize(true)
    } else {
      setInitialize(true)
    }
  }, [])

  useEffect(() => {
    if (reduxShared) {
      // if the page was refreshed, we need to delete all records from EVGCompactTable
      // only when this task is completed, we can start working with the module
      if (reduxShared.rxIsPageRefresh) {
        deleteModuleFromDB()
      } else {
        // we can start working with the module
        setInitialize(true)
      }
    }
  }, [deleteModuleFromDB, reduxShared])

  return {
    initialize
  }
}

export default useInitialConfiguration