import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import { useEffect } from 'react'
import useGetList from './useGetList'

/**
 * @description Custom hook to show more events
 * @returns {Object} { showMore }
 * @example
 * const { showMore } = useShowMore()
 */
const useShowMore = () => {
  const [{ ctxParams, ctxLoadMore, ctxList }, dispatch]:any = useEventsGroupContext()
  const { getList } = useGetList()

  const showMore = () => {
    const newParams = {
      ...ctxParams,
      offset: ctxList.offset === 0 ? 4 : ctxList.offset + 8,
      limit: 8
    }
    dispatch({ type: 'SET_PARAMS', payload: newParams })
    dispatch({ type: 'SET_LOAD_MORE', payload: true })
  }

  useEffect(() => {
    if (ctxParams && ctxList && ctxParams?.offset !== ctxList?.offset && ctxLoadMore) {
      getList()
    }
  // Note: dont add database operations to dependencies as this will duplicate the records in DB
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctxLoadMore, ctxParams])

  return {
    showMore
  }
}

export default useShowMore