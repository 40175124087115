import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import { parseDate } from '../utils'

interface IParams {
  isFeatured?: boolean | null
  cityId?: string | null
  organisers?: string | null
  labels?: string | null
  eventTypes?: string | null
  access?: string | null
  includeFilters?: boolean
  online?: boolean
  limit?: number
  offset?: number
  orderBy?: string | null
  exhibition?: boolean | null
  startDate?: Date | null
  endDate?: Date | null
  dateRange?: string | null
  hostType?: string | null
  virtual?: boolean | null
}

/**
 * @description Custom hook to get params from CMSProps and set them in context
 * @returns {Object} { getParams }
 * @example
 * const { getParams } = useGetParams()
 */
const useGetParams = () => {
  const [{ CMSProps, ctxLoaded }, dispatch]: any = useEventsGroupContext()

  const getParams = () => {
    // orderBy
    let orderBy: string | null = ''
    switch (CMSProps.evg_grp1_sort_by) {
      case 'CREATION_DATE':
        orderBy = 'dateCreated DESC'
        break
      case 'END_DATE':
        orderBy = 'endDate ASC'
        break
      case 'START_DATE':
        orderBy = 'startDate ASC'
        break
      case 'STARTING_SOON':
        orderBy = 'imminence DESC'
        break
      default:
        orderBy = null
    }

    // exhibition
    let exhibition: boolean | null = false
    switch (CMSProps.evg_grp1_event_access_type) {
      case 'EVENTS':
        exhibition = false
        break
      case 'EXHIBITIONS':
        exhibition = true
        break
      default:
        exhibition = null
    }

    // startDate, endDate, dateRange
    let startDate: Date | null = null
    let endDate: Date | null = null
    let dateRange: string | null = null
    switch (CMSProps.evg_grp1_time_frame) {
      case 'option_key_1':
        startDate = null
        endDate = null
        break
      case 'option_key_2':
        dateRange = '0,1'
        break
      case 'option_key_3':
        dateRange = '0,7'
        break
      default:
        dateRange = CMSProps.evg_grp1_time_frame ? parseDate(CMSProps.evg_grp1_time_frame) : null
    }

    // host type (galleries, art basel, partner, all)
    let hostType: string | null = null
    if (CMSProps.aevd_grp1_event_host_type && CMSProps.aevd_grp1_event_host_type !== '0') {
      let listOfHostTypes = CMSProps.aevd_grp1_event_host_type
      listOfHostTypes = listOfHostTypes.split(',')
      const index = listOfHostTypes.indexOf('0')
      if (index !== -1) {
        listOfHostTypes.splice(index, 1)
      }
      listOfHostTypes = listOfHostTypes.toString()
      listOfHostTypes = listOfHostTypes.replace('GALLERIES', 'GALLERY')
      listOfHostTypes = listOfHostTypes.replace('PARTNER', 'SPONSOR')
      hostType = listOfHostTypes || null
    }

    // Virtual flag
    let virtual: boolean | null = null
    if (CMSProps.evg_grp1_event_access_type === 'VIRTUAL_EVENTS') {
      virtual = true
    }
    if (CMSProps.evg_grp1_event_access_type === '0' || !CMSProps.evg_grp1_event_access_type) {
      virtual = null
    }
    if (CMSProps.evg_grp1_event_access_type === 'EVENTS') {
      virtual = false
    }

    let initialParams: IParams = {
      isFeatured: CMSProps.evg_grp1_is_featured === 'true' || null,
      cityId: CMSProps.evg_grp1_show_city || null,
      organisers: CMSProps.evg_grp1_organiser || null,
      labels: CMSProps.evg_grp1_event_label || null,
      eventTypes: CMSProps.evg_grp1_category || null,
      access: CMSProps.aevd_grp1_event_access || null,
      includeFilters: false,
      online: false,
      limit: 4,
      offset: 0,
      orderBy,
      exhibition,
      startDate,
      endDate,
      dateRange,
      hostType,
      virtual
    }
    initialParams = Object.fromEntries(Object.entries(initialParams).filter(([_, v]) => v != null))

    if (!ctxLoaded) {
      dispatch({ type: 'SET_PARAMS', payload: initialParams })
    }
  }

  return {
    getParams
  }
}

export default useGetParams
