import useVersion from '@hooks/useVersion'
import { useEffect, useCallback } from 'react'
import useDB from './useDB'
import { useEventsGroupContext } from '../context/useEventsGroupContext'
import { EVGCompactTable } from '../../../IndexedDB/config'

const useSoftwareVersion = () => {
  const [{ CMSProps }]: any = useEventsGroupContext()
  const { getModule } = useDB()
  const { getVersion } = useVersion()

  const checkVersion = useCallback(async () => {
    const evgCompactModuleInDB = await getModule(CMSProps.id)
    if (evgCompactModuleInDB) {
      try {
        const version:any = await getVersion()
        if (version && !evgCompactModuleInDB.version) {
          await EVGCompactTable.update(evgCompactModuleInDB.id, {
            ...evgCompactModuleInDB,
            version
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  },[CMSProps.id, getModule, getVersion])

  useEffect(() => {
    checkVersion()
  }, [checkVersion])

  return {}
}

export default useSoftwareVersion
