import { FC } from 'react'
import EventsGroup from '@components/ot-modules/Events/EventsGroup'
import EventsGroupCompact from '../../components/EventsGroupCompact'
import EventsGroupProvider from './provider/EventsGroupProvider'
import useInitialConfiguration from './hooks/useInitialConfiguration'

interface IEventsGroupModule {
  props: {
    [x: string]: string
   }
  evg_grp1_compact_variant: string
}

/**
 * @module EventsGroupModule
 * @param props
 * @description This is the main module for the EventsGroup component.
 * It checks if the compact variant is enabled and renders the appropriate component.
 */
const EventsGroupModule: FC<IEventsGroupModule> = (props) => {
  const { initialize } = useInitialConfiguration()

  // compact EVG
  if (props?.evg_grp1_compact_variant === 'true' && initialize) {
    return (
      <EventsGroupProvider CMSProps={props}>
        <EventsGroupCompact />
      </EventsGroupProvider>
    )
  }

  // default EVG
  if (props?.evg_grp1_compact_variant !== 'true') {
    return (
      <EventsGroup {...props} />
    )
  }
  return null
}

export default EventsGroupModule
