import { useCallback } from 'react'
import { api } from '../services/_http'

const useVersion = () => {
  const getVersion = useCallback(async () => {
    let req:any = null
    try {
      req = await api.get('/nodeApi/version')
      if (req.ok && req.data?.version) {
        req = req.data.version
      }
    } catch (error) {
      console.error(error)
    }
    return req
  }, [])

  return { getVersion }
}

export default useVersion
