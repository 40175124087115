import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import { useCallback } from 'react'
import { Events as EventsService } from '@services'
import useDB from './useDB'

/**
 * @description Custom hook to get list of events from API and save it in IndexedDB
 * @returns {Object} { getList }
 * @example
 * const { getList } = useGetList()
 */
const useGetList = () => {
  const [{ ctxParams, ctxList, ctxLoadMore, CMSProps, router }, dispatch]: any = useEventsGroupContext()
  const { getModule, addModule, updateModule } = useDB()

  const prepareResponse = useCallback(
    (responseItems, responseInDB = null) => {
      if (!ctxList || (ctxList && ctxParams.offset === 0)) {
        return responseItems
      }
      if (ctxLoadMore) {
        return {
          ...responseItems,
          items: [...responseInDB.data.items, ...responseItems.items]
        }
      }
      return {
        ...responseItems,
        items: [...ctxList.items, ...responseItems.items]
      }
    },
    [ctxList, ctxLoadMore, ctxParams]
  )

  const getList = useCallback(async () => {
    const evgCompactModuleInDB = await getModule(CMSProps.id)

    // NOT IN DB? REQUEST API AND ADD RESPONSE TO DATABASE
    if (!evgCompactModuleInDB) {
      try {
        const req: any = await EventsService.getUpcomingEvents(ctxParams)
        if (req?.ok && req?.data?.events) {
          dispatch({ type: 'SET_LIST', payload: prepareResponse(req.data.events) })
          await addModule({
            mid: CMSProps.id,
            name: CMSProps.moduleName,
            data: req.data.events,
            params: ctxParams, // tbd
            paths: req.data.events?.items.map((item) => `events/detail/${item.id}`) || [],
            origin: router.query.slug || router.asPath,
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString()
          })
        }
      } catch (error) {
        console.error(error)
      }
    } else if (evgCompactModuleInDB) {
      // IS IN DB? USE IT AND DISPATCH TO CTX
      // IS LOADING MORE USING DB
      if (ctxLoadMore) {
        try {
          const req: any = await EventsService.getUpcomingEvents(ctxParams)
          if (req?.ok && req?.data?.events) {
            dispatch({ type: 'SET_LIST', payload: prepareResponse(req.data.events, evgCompactModuleInDB) })
            await updateModule(evgCompactModuleInDB.id, {
              ...evgCompactModuleInDB,
              data: prepareResponse(req.data.events, evgCompactModuleInDB),
              params: ctxParams,
              paths:
                prepareResponse(req.data.events, evgCompactModuleInDB)?.items?.map(
                  (item) => `events/detail/${item?.id}`
                ) || [],
              origin: router?.query?.slug || router?.asPath,
              scrollPosition: window.scrollY,
              listHeight: document?.getElementById(CMSProps.id)?.clientHeight || 0,
              documentHeight: document?.body?.clientHeight || 0,
              dateUpdated: new Date().toISOString()
            })
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        // IS JUST LOADING FIRST TIME, BUT NOT LOADING MORE
        dispatch({ type: 'SET_INIT_SCROLL', payload: true })
        dispatch({ type: 'SET_LIST', payload: prepareResponse(evgCompactModuleInDB.data, evgCompactModuleInDB) })
      }
    }

    // FINISHING...
    dispatch({ type: 'SET_LOADED', payload: true })
    dispatch({ type: 'SET_LOAD_MORE', payload: false })

  // Note: dont add database operations to dependencies as this will duplicate the records in DB
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CMSProps.id,
    CMSProps.moduleName,
    ctxLoadMore,
    ctxParams,
    dispatch,
    prepareResponse,
    router.asPath,
    router.query.slug
  ])

  return {
    getList
  }
}

export default useGetList
