import { useEffect } from 'react'
import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import { EVGCompactTable } from '../../../IndexedDB/config'
import useDB from './useDB'

/**
 * @description Custom hook to update scroll position in IndexedDB
 * @returns {Object} {}
 * @example
 * const {} = useUpdateScroll()
 */
const useUpdateScroll = () => {
  const [{ CMSProps }]: any = useEventsGroupContext()
  const { getModule } = useDB()

  useEffect(() => {
    const timer = setInterval(async () => {
      const evgCompactModuleInDB = await getModule(CMSProps.id)
      if (evgCompactModuleInDB && evgCompactModuleInDB?.scrollPosition !== window.scrollY) {
        await EVGCompactTable.update(evgCompactModuleInDB.id, {
          ...evgCompactModuleInDB,
          scrollPosition: window.scrollY
        })
      }
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CMSProps.id])

  return {}
}

export default useUpdateScroll
