import React, { useReducer, useState, useMemo, FC } from 'react'
import { withRouter } from 'next/router'
import { environment } from '@dmi-mch/utils'
import { initialState, reducer, StateContext } from '../context/useEventsGroupContext'

interface IEventsGroupProvider {
  CMSProps: any
  children: any
  router: any
}

const EventsGroupProvider: FC<IEventsGroupProvider> = (props) => {
  const { CMSProps, children, router } = props

  const [hydratedInitialState] = useState({
    ctxParams: null,
    ctxList: null,
    ctxLoaded: false,
    ctxLoadMore: false,
    ctxInitScroll: false,
    CMSProps: CMSProps,
    router: router
  })

  useMemo(() => {
    if (JSON.stringify(Object.keys(hydratedInitialState).sort()) !== JSON.stringify(Object.keys(initialState).sort())) {
      environment.isNotPro && console.error('Initial state vs context doesnt match!')
    }
  }, [hydratedInitialState])

  // @ts-ignore
  return <StateContext.Provider value={useReducer(reducer, hydratedInitialState)}>{children}</StateContext.Provider>
}

export default withRouter(EventsGroupProvider)
