import useGetParams from '@modules/EventsGroup/hooks/useGetParams'
import { useEventsGroupContext } from '@modules/EventsGroup/context/useEventsGroupContext'
import { useEffect } from 'react'
import useGetList from '@modules/EventsGroup/hooks/useGetList'
import useShowMore from '@modules/EventsGroup/hooks/useShowMore'
import useUpdateListHeight from '@modules/EventsGroup/hooks/useUpdateListHeight'
import useUpdateScroll from '@modules/EventsGroup/hooks/useUpdateScroll'
import useScrollTo from '@modules/EventsGroup/hooks/useScrollTo'
import useVersion from '@modules/EventsGroup/hooks/useVersion'
import ListView from './ListView'

const EventsGroupCompact = () => {
  const [{ CMSProps, ctxList, ctxParams, ctxLoaded, ctxLoadMore }]: any = useEventsGroupContext()
  const { getParams } = useGetParams()
  const { getList } = useGetList()
  const { showMore } = useShowMore()

  // get params
  useEffect(() => {
    !ctxParams && getParams()
  }, [ctxParams, getParams])

  // get list
  useEffect(() => {
    ctxParams && !ctxList && getList()
  }, [ctxList, ctxParams, getList])

  // update list height
  useUpdateListHeight()

  // update scroll position
  useUpdateScroll()

  // scroll to
  useScrollTo()

  // get version
  useVersion()

  return ctxLoaded ? (
    <ListView
      moduleTitle={CMSProps?.evg_grp1_section_title || ''}
      mID={CMSProps.id}
      list={ctxList?.items?.length > 0 ? ctxList?.items : []}
      hasMore={ctxList?.hasMore}
      showMore={showMore}
      ctxLoadMore={ctxLoadMore}
      showMoreLabel={CMSProps?.evg_grp1_show_more_label || ''}
      viewAllLabel={CMSProps?.evg_grp1_view_all || ''}
      viewAllLink={CMSProps?.evg_grp1_view_all_lnk || ''}
    />
  ) : null
}

export default EventsGroupCompact