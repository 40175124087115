import React, { FC } from 'react'
import { Container, Row, Col, Button } from '@mch-group/uikit-components'
import EventCardView from '@components/ot-modules/Events/components/EventCardList'
import { CustomLinkTag } from '@components'

interface IListView {
  mID: string
  moduleTitle?: string
  hasMore: boolean
  showMoreLabel?: string
  list: any // tbd
  showMore: () => void
  ctxLoadMore: boolean
  viewAllLabel?: string
  viewAllLink: string
}

const ListView: FC<IListView> = ({
  mID,
  moduleTitle,
  list,
  viewAllLabel,
  viewAllLink,
  showMoreLabel,
  hasMore,
  ctxLoadMore,
  showMore
}) => (
  <section id={mID} className='events-group-compact mb-8'>
    <Container className='px-5 px-lg-8 px-md-7'>
      {/* MODULE TITLE */}
      {moduleTitle && (
        <Row className='g-5 g-md-7 g-lg-8 pb-md-7 pb-xs-7 pb-sm-7 pb-lg-9 pb-xl-9 mb-5 mb-sm-0'>
          <Col xs={5} sm={5} md={8} lg={8} xl={8} className='pt-md-7 title-break-word'>
            <h2 className='header-uppercase-1 h1'>{moduleTitle}</h2>
          </Col>
          {viewAllLabel && (
            <Col xs={7} sm={7} md={4} lg={4} xl={4}>
              <CustomLinkTag url={viewAllLink}>
                <Button className='px-7 py-5 float-end'>{viewAllLabel}</Button>
              </CustomLinkTag>
            </Col>
          )}
        </Row>
      )}

      {/* LIST OF EVENTS */}
      {list.length > 0 ? (
        <Row gutter='g-5 g-md-7 g-xl-7 mb-0 pb-0'>
          {list?.map((item, i: number) => (
            <Col key={i} xs={12} sm={12} md={6} lg={4} xl={3} className='mb-lg-8 mb-md-8 d-flex'>
              <EventCardView item={item} isCollectionCard={false} />
            </Col>
          ))}
        </Row>
      ) : null}

      {/* SHOW MORE BUTTON */}
      {hasMore && (
        <Row className='g-5 g-md-7 g-xl-7 mt-8 mt-sm-8 mt-md-0 pt-0'>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className='justify-content-center d-flex mt-0 pt-0'>
            <Button variant='secondary' onClick={showMore} disabled={ctxLoadMore} isLoading={ctxLoadMore} className='mt-0'>
              {showMoreLabel || 'Show more'}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  </section>
)

export default ListView
