/**
 * Parse date to send to the API request
 * @param date {string} date
 * @returns {string|null} dateToSend
 * @example
 * const dateToSend = parseDate('2021-05-01T00:00:00|2021-05-31T00:00:00')
 * // dateToSend = '2021-05-01T00:00:00,2021-05-31T00:00:00'
 */
const parseDate = (date) => {
  const datesArr = date.split('|')
  const datesLength = datesArr.length
  const replaceStr = (d) => `${d}:00`
  let dateToSend:string|null = null
  if (datesLength >= 2) {
    dateToSend = `${replaceStr(datesArr[1])}`
  }
  if (datesLength === 3) {
    dateToSend = `${dateToSend},${replaceStr(datesArr[2])}`
  }
  return dateToSend
}

export {
  parseDate
}