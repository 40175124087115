import { useCallback } from 'react'
import { EVGCompactTable } from '../../../IndexedDB/config'
import { logger } from '@dmi-mch/utils'

/**
 * @description Custom hook to use IndexedDB methods for EVGCompactTable
 * @returns {Object} { addModule, getModule, updateModule, deleteModule }
 * @example
 * const { addModule, getModule, updateModule, deleteModule } = useDB()
 */
const useDB = () => {
  const getModule = useCallback(async (moduleId) => {
    try {
      return await EVGCompactTable.get({ mid: moduleId })
    } catch (err) {
      logger(err)
    }
  }, [])

  const addModule = async (data) => {
    try {
      await EVGCompactTable.add(data)
    } catch (err) {
      logger(err)
    }
  }

  const updateModule = async (moduleId, data) => {
    try {
      await EVGCompactTable.update(moduleId, data)
    } catch (err) {
      logger(err)
    }
  }

  const deleteModule = async (moduleId) => {
    try {
      await EVGCompactTable.delete(moduleId)
    } catch (err) {
      logger(err)
    }
  }

  return {
    addModule,
    getModule,
    updateModule,
    deleteModule
  }
}

export default useDB