import { func, string } from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { Container, Row, Col, Button } from '@mch-group/uikit-components'
import cssClasses from '@constants/cssClasses'
import { CustomLinkTag } from '@components'
import EventCardList from '@components/ot-modules/Events/components/EventCardList'
import useEventsGroup from './hooks/useEventsGroup'

const EventsGroup = ({ ...props }) => {
  const { eventsList, linkToBrowsePage } = useEventsGroup({ props })

  return eventsList && eventsList.length ? (
    <div className={classNames(props.className, cssClasses.CMS_MODULE)}>
      <Container className='px-5 px-lg-8 px-md-7 cms-module'>
        <Row className='g-5 g-md-7 g-lg-8 pb-md-7 pb-xs-7 pb-sm-7 pb-lg-9 pb-xl-9'>
          <Col xs={5} sm={5} md={8} lg={8} xl={8} className='pt-md-7 title-break-word'>
            <h2 className='header-uppercase-1 h1'>{props.evg_grp1_section_title || ''}</h2>
          </Col>
          {/* If there is text label, there will be a link */}
          {props.evg_grp1_view_all && (
            <Col xs={7} sm={7} md={4} lg={4} xl={4}>
              {/* Example URL /events/browse?cityId=1&date=2018-10-31&offset=0 */}
              <CustomLinkTag url={linkToBrowsePage}>
                <Button
                  className='px-7 py-5 float-end'
                >
                  {props.evg_grp1_view_all}
                </Button>
              </CustomLinkTag>
            </Col>
          )}
        </Row>
        {eventsList.length ? (
          <Row className='g-5 g-md-7 g-lg-8'>
            {eventsList.map((event) => (
              <Col key={Math.random()} xs={12} sm={12} md={4} lg={4} className='mb-lg-8 mb-md-8 d-flex'>
                <EventCardList
                  item={event}
                  isCollectionCard={false}
                />
              </Col>
            ))}
          </Row>
        ) : null}
      </Container>
    </div>
  ) : null
}

EventsGroup.propTypes = {
  className: string,
  evg_grp1_is_featured: string,
  evg_grp1_show_city: string,
  evg_grp1_event_access_type: string,
  evg_grp1_organiser: string,
  evg_grp1_event_label: string,
  evg_grp1_category: string,
  evg_grp1_view_all_lnk: string,
  evg_grp1_section_title: string,
  evg_grp1_view_all: string,
  evg_grp1_time_frame: string,
  evg_grp1_sort_by: string,
  aevd_grp1_event_access: string,
  aevd_grp1_event_host_type: string,
  cbComponentReady: func
}

export default styled(EventsGroup)`
  @media (max-width: ${global.uikitVars.breakpointMdMax}) {
    .image-frame > button {
      display: none;
    }
  }

  @media (max-width: ${global.uikitVars.breakpointSmMax}) {
    .title-break-word {
      word-break: break-word;
      padding-bottom: 24px;
    }
  }
`
