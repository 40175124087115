import { ICompactInitialState } from '../../../IndexedDB/types'
import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PARAMS':
      return {
        ...state,
        ctxParams: action.payload
      }
    case 'SET_LIST':
      return {
        ...state,
        ctxList: action.payload
      }
    case 'SET_LOADED':
      return {
        ...state,
        ctxLoaded: action.payload
      }
    case 'SET_INIT_SCROLL':
      return {
        ...state,
        ctxInitScroll: action.payload
      }
    case 'SET_LOAD_MORE':
      return {
        ...state,
        ctxLoadMore: action.payload
      }
    default:
      return { ...state }
  }
}

export const initialState: ICompactInitialState = {
  ctxParams: null,
  ctxList: null,
  ctxLoaded: false,
  ctxInitScroll: false,
  ctxLoadMore: false,
  CMSProps: null,
  router: null
}

export const StateContext = createContext(initialState)

export const useEventsGroupContext = () => useContext(StateContext)
